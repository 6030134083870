import React from "react"
import { graphql } from "gatsby"
import { TransitionPortal } from "gatsby-plugin-transition-link"
import Layout from "../components/layout/layout"
import RichTextField from "../components/textfield/rich"
import PageTransition from "../components/transition/page"
import ContentHeader from "../components/layout/content-header"

import SEO from "../components/seo/seo"

class ArticleTemplate extends React.Component {
  constructor(props) {
    super(props)
    this.pageTransition = React.createRef()
  }

  animate() {
    this.pageTransition.current.play()
  }

  render() {
    const locale = this.props.pageContext.locale
    const article = this.props.data.contentfulArticle

    return (
      <Layout theme="light" path={this.props.path} locale={locale} exit={{ length: 1.75, trigger: () => this.animate() }} entry={{ delay: 0.875 }}>
        <SEO
          title={article.seo.title}
          description={article.seo.description}
          keywords={article.seo.keywords && article.seo.keywords.keywords}
          image={article.seo.image.file.url}
        />
        <div className={"container mx-auto"}>
          <div className={"w-full lg:w-10/12 lg:px-4 mx-auto "}>
            <ContentHeader title={article.title} imagePath={article.image && article.image.file.url} />
          </div>
          <div className={"container mx-auto"}>
            <div className={"px-2 w-2/3 mx-auto"}>
              <RichTextField json={article.content.json} locale={locale} />
            </div>
          </div>
        </div>
        <TransitionPortal>
          <PageTransition ref={this.pageTransition} />
        </TransitionPortal>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query articleQuery($slug: String!) {
    contentfulArticle(slug: { eq: $slug }) {
      title
      slug
      short
      category {
        slug
      }
      image {
        file {
          url
        }
      }
      content {
        json
      }
      tags
      seo {
        title
        description
        keywords {
          keywords
        }
        image {
          file {
            url
          }
        }
      }
    }
  }
`

export default ArticleTemplate
